import React from 'react'
import EditIcon from '@material-ui/icons/Edit';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
    editButton: {
        minWidth: 0
    }
});

class EventInfoWindow extends React.Component {
    render() {
        const date = new Date(this.props.event.date)
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
        return (
            <div>
                <h2>{this.props.event.title}<Button className={this.props.classes.editButton} onClick={this.props.onEditEventClick}><EditIcon /></Button></h2>
                <p>{date.toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
                <p>{this.props.event.description}</p>
            </div>
        )
    }
}

EventInfoWindow.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventInfoWindow);