import { Grid } from '@material-ui/core'
import React from 'react'

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import EventCard from './EventCard';

const styles = (theme) => ({
    root: {

    },
});

/**
 * requires props.event
 */
class EventGrid extends React.Component {
    render() {
        return (
            <div>
                <Grid container spacing={2}>
                    {this.props.events.sort((a, b) => a.date - b.date).map((event) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={event._id}>
                            <EventCard onClick={this.props.onEventClick} event={event} locations={this.props.locations} />
                        </Grid>
                    ))}
                </Grid>
            </div>
        )
    }
}

EventGrid.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventGrid);