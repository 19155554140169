import React from 'react';
import { TextField, Button, Container, Checkbox, FormControlLabel, Link, Paper } from '@material-ui/core';
import { withRouter } from "react-router";
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { AuthContext } from '../../AuthContext';

const styles = theme => ({
    root: {
        width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
    description: {
        minHeight: 50
    },
    latField: {
        width: "60px"
    },
    lngField: {
        width: "60px",
        marginLeft: "16px"
    }
});


class CreateMapForm extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            name: "",
            description: "",
            lat: 45,
            lng: -94,
            useMapStyle: false,
            mapStyle: "",

            saveStatus: "",
            dataLoaded: !this.props.mapId
        }

        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        if (this.props.mapId) {
            this.context.api.get(`/api/map/${this.props.mapId}`, true)
                .then((data) => {
                    if (data.worked) {
                        const map = data.data
                        console.log("MAP", map)
                        const mapStyle = map.details.options ? map.details.options.styles : null
                        const lat = map.details.center ? map.details.center.lat : 45
                        const lng = map.details.center ? map.details.center.lng : -94
                        this.setState({
                            name: map.name,
                            description: map.description,
                            lat: lat,
                            lng: lng,
                            useMapStyle: !!mapStyle,
                            mapStyle: mapStyle ? JSON.stringify(mapStyle, null, 2) : "",
                            dataLoaded: true,
                        })
                    }
                })
        }
    }

    handleChange(key) {
        return (event) => {
            let value;
            if (event.target.type === "checkbox") {
                value = event.target.checked
            } else {
                value = event.target.value
            }
            this.setState({ [key]: value });
            console.log(event)
        }
    }

    validate() {
        if (!this.state.name || !this.state.name.trim()) {
            this.setState({ saveStatus: "Name is required" })
            return false
        }
        if ((!this.state.lat && this.state.lat !== 0) || isNaN(this.state.lat) || this.state.lat > 90 || this.state.lat < -90) {
            this.setState({ saveStatus: "Please enter a valid latitude" })
            return false
        }
        if ((!this.state.lng && this.state.lng !== 0) || isNaN(this.state.lng)) {
            this.setState({ saveStatus: "Please enter a valid longitude" })
            return false
        }
        if (this.state.useMapStyle) {
            try {
                JSON.parse(this.state.mapStyle)
            } catch (e) {
                this.setState({ saveStatus: "Map style must be JSON" })
                return false
            }
        }
        return true;
    }

    handleSubmit() {
        if (this.validate()) {
            const payload = {
                map: {
                    name: this.state.name,
                    description: this.state.description,
                    center: {
                        lat: this.state.lat,
                        lng: this.state.lng,
                    },
                    options: {}
                }
            }
            if (this.state.useMapStyle) {
                payload.map.options.styles = JSON.parse(this.state.mapStyle)
            }
            if (!this.props.mapId) { //Create map if there is no mapId
                fetch('/api/map', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                    body: JSON.stringify(payload)
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Create Map response:', data)
                        if (data.worked) {
                            this.props.history.push(`/map/${data.map._id}`)
                        } else {
                            this.setState({ saveStatus: "Failed to create map" })
                        }
                    })
                    .catch(error => console.error('Map Create Error', error))
            } else { //Update map if there is a mapId
                this.context.api.put(`/api/map/options/${this.props.mapId}`, payload, true)
                    .then(data => {
                        console.log('update map options response:', data)
                        if (data.worked) {
                            // this.setState({saveStatus: <div>Successfully updated. <Link href="#" onClick={() => this.props.history.push(`/map/${data.updatedMap._id}`)}>View map</Link>.</div>})
                            this.props.history.push('/')
                        } else {
                            this.setState({ saveStatus: "Failed to update. Try again later" })
                        }
                    })
            }
        }
    }

    handleCancel() {
        this.props.history.push('/')
    }


    render() {
        return (
            <Paper style={{flex: 1}}>
                <Container>
                    <h2>{this.props.mapId ? "Map Options" : "Create Map"}</h2>
                    {this.state.dataLoaded &&
                        <form noValidate autoComplete="off">
                            <div>
                                <TextField defaultValue={this.state.name} label="Name" onBlur={this.handleChange('name')} />
                            </div>
                            <div>
                                <TextField defaultValue={this.state.description} multiline fullWidth label="Description" onBlur={this.handleChange('description')} />
                            </div>
                            <div>
                                <TextField className={this.props.classes.latField} defaultValue={this.state.lat} multiline fullWidth label="Latitude" onBlur={this.handleChange('lat')} />
                                <TextField className={this.props.classes.lngField} defaultValue={this.state.lng} multiline fullWidth label="Longitude" onBlur={this.handleChange('lng')} />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.useMapStyle} onChange={this.handleChange('useMapStyle')} name="useMapStyle" />}
                                    label="Use JSON map style (Advanced)"
                                />
                            </div>
                            {this.state.useMapStyle &&
                                <div>
                                    <p><Link href="https://mapstyle.withgoogle.com/" target="_blank">Style tool (old style wizard)</Link></p>
                                    <TextField
                                        label="Map Style JSON (Optional | Advanced)"
                                        name="mapStyle"
                                        defaultValue={this.state.mapStyle}
                                        onChange={this.handleChange("mapStyle")}
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        style={{ marginBottom: 15, width: 500 }}
                                    />
                                </div>
                            }
                            {this.state.saveStatus &&
                                <div>
                                    <p>{this.state.saveStatus}</p>
                                </div>
                            }
                            <div>
                                <Button onClick={this.handleCancel} color="secondary">
                                    Cancel
                        </Button>
                                <Button onClick={this.handleSubmit} color="primary">
                                    Save
                        </Button>
                            </div>
                        </form>
                    }
                </Container>
            </Paper>
        );
    }
}

CreateMapForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(CreateMapForm))