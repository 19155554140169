import { Container } from '@material-ui/core';
import React from 'react';
import { AuthContext } from '../../AuthContext';
import AccountStats from './AccountStats';
import ChangePasswordForm from './ChangePasswordForm';

export default class AccoutSettings extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props)


        this.openChangePasswordModal = this.openChangePasswordModal.bind(this)
        this.closeChangePasswordModal = this.closeChangePasswordModal.bind(this)
    }

    state = {
        changePasswordModalOpen: false
    }

    closeChangePasswordModal() {
        this.setState({ changePasswordModalOpen: false })
    }
    openChangePasswordModal() {
        this.setState({ changePasswordModalOpen: true })
    }

    render() {
        const user = this.context.user
        console.log(user.createdAt)
        const accountCreationDate = user.createdAt ?
            (new Date(user.createdAt)).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
            : "Prior to February 2021"
        return (
            <Container>
                <div>
                    <h1>{this.context.user.profileName}</h1>
                    <p>Account created: {accountCreationDate}</p>
                </div>
                <AccountStats />
                <ChangePasswordForm />
                {/* <Button onClick={this.openChangePasswordModal} color="primary" >Change password</Button>
                <Modal
                    id="create-location-modal"
                    open={this.state.changePasswordModalOpen}
                    onClose={this.closeChangePasswordModal}
                >
                    <Paper>
                    </Paper>
                </Modal> */}

            </Container>
        )
    }
}