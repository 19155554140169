import DateFnsUtils from '@date-io/date-fns';
import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import PropTypes from 'prop-types'

const styles = (theme) => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    filterHeader: {
        cursor: "pointer",
        userSelect: 'none',
    }
});

class FilterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.defaultFilters,
            expanded: false,
        }

        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.onChange = this.onChange.bind(this)
        this.handleClearFilters = this.handleClearFilters.bind(this)
    }
    defaultFilters = {
        tags: [],
        match: "any",
        dateMatching: false,
        dateStart: new Date(),
        dateEnd: new Date(),
    }
    handleClearFilters() {
        const change = this.defaultFilters
        this.setState(change)
        this.onChange(change)
    }
    handleTagsChange(e, value) {
        const change = { tags: value }
        this.setState(change)
        this.onChange(change)
    }
    handleChange(key) {
        return (e) => {
            const value = e.target.type === "checkbox" ? e.target.checked : e.target.value
            const change = { [key]: value }
            this.setState(change)
            this.onChange(change)
        }
    }
    handleDateChange(key) {
        return (date, dateString) => {
            const change = { [key]: date }
            this.setState(change)
            this.onChange(change)
        }
    }
    toggleExpanded() {
        this.setState({ expanded: !this.state.expanded })
    }
    onChange(change) {
        if (this.props.onChange) {
            //returning the state object quickly
            //results in returning an old state
            const returnState = {
                ...this.state,
                ...change
            }
            console.log(returnState)
            this.props.onChange(returnState)
        } else {
            console.log("onChange is unspecified")
        }
    }
    render() {
        return (
            // <div className={this.props.classes.paper}>
            <div>
                {/* <Button variant="outlined" onClick={this.toggleExpanded}>Filter</Button> */}
                <h2 className={this.props.classes.filterHeader} onClick={this.toggleExpanded}>Filter</h2>
                {this.state.expanded &&
                    <div>
                        <Autocomplete
                            id="tags"
                            multiple
                            filterSelectedOptions
                            value={this.state.tags}
                            onChange={this.handleTagsChange}
                            options={[...this.props.availableTags]}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="tags"
                                    placeholder="tags"
                                />
                            )}
                        />
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Match</FormLabel>
                            <RadioGroup row aria-label="match" name="match" value={this.state.match} onChange={this.handleChange("match")}>
                                <FormControlLabel value="any" control={<Radio />} label="Any" />
                                <FormControlLabel value="all" control={<Radio />} label="All" />
                            </RadioGroup>
                        </FormControl>
                        <div>
                            <FormControlLabel
                                control={<Checkbox color="primary" onChange={this.handleChange("dateMatching")} checked={this.state.dateMatching} name="filterDates" />}
                                label="Dates"
                            />
                            {this.state.dateMatching &&
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        label="Start Date"
                                        format="MM/dd/yyyy"
                                        value={this.state.dateStart}
                                        onChange={this.handleDateChange("dateStart")}
                                        minDate={(new Date()).setFullYear(0)}
                                    />
                                    <KeyboardDatePicker
                                        label="End date"
                                        format="MM/dd/yyyy"
                                        value={this.state.dateEnd}
                                        onChange={this.handleDateChange("dateEnd")}
                                        minDate={(new Date()).setFullYear(0)}
                                    />
                                </MuiPickersUtilsProvider>
                            }
                            <Button onClick={this.handleClearFilters}>Clear Filters</Button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

FilterForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FilterForm);