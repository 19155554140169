import { Button } from '@material-ui/core'
import React from 'react'
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'

const styles = (theme) => ({
    button: {
        "font-size": "0.75rem",
    },
    editButton: {
        minWidth: 0
    }
});

//Takes a location in props
class LocationInfoWindow extends React.Component {
    render() {
        return (
            <div>
                <h2>{this.props.location.name}<Button className={this.props.classes.editButton} onClick={this.props.onEditLocationClick}><EditIcon/></Button></h2>
                <p>{this.props.location.description}</p>
                <Button className={this.props.classes.button} onClick={this.props.onAddLocationClick}>Add Event</Button>
                {this.props.children.map((childElement) => (
                    <div>
                        <hr />
                        {childElement}
                    </div>
                ))}
            </div>
        )
    }
}

LocationInfoWindow.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LocationInfoWindow)