import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core'
import React from 'react'

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'

const styles = (theme) => ({
    root: {
        maxWidth: 345,
        height: 300,
    },
    description: {
        maxHeight: 20
    }
});

/**
 * requires props.event
 */
class EventCard extends React.Component {
    render() {
        // const placeholderURI = "/public/user-images/sfw_apa_2013_28627_227139_catherinemcentee_tufted_titmouse_kk_0.jpg"
        const placeholderURI = "/public/gray-placeholder.png"
        const parentLocation = this.props.locations.find((location) => location._id === this.props.event.parentLocation)
        let eventDate = new Date(this.props.event.date)
        eventDate.setMinutes(eventDate.getMinutes() + eventDate.getTimezoneOffset())
        return (
            <div>
                <Card className={this.props.classes.root}>
                    <CardActionArea onClick={() => this.props.onClick(this.props.event)}>
                        {/* {this.state.event.image && */}
                        <CardMedia
                            component="img"
                            // alt={this.state.event.image.alt}
                            height="140"
                            image={(this.props.event.images && this.props.event.images.length > 0) ? `/api/image/${this.props.event.images[0].image}` : placeholderURI}
                        // title={this.state.event.image.title}
                        />
                        {/* } */}
                        <CardContent>
                            {parentLocation &&
                                <Typography variant="overline" component="p">
                                    {parentLocation.name}
                                </Typography>
                            }
                            <Typography gutterBottom variant="h5" component="h2">
                                {this.props.event.title}
                            </Typography>
                            <Typography variant="caption" color="textSecondary" component="p">
                                {eventDate.toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                            </Typography>
                            <div className={this.props.classes.description}>
                                <Typography variant="body2" color="textPrimary" component="p">
                                    {this.props.event.description}
                                </Typography>
                            </div>
                        </CardContent>
                    </CardActionArea>
                    {/* <CardActions>
                        <Button size="small" color="primary">
                            Share
                            </Button>
                            <Button size="small" color="primary">
                            Learn More
                        </Button>
                    </CardActions> */}

                </Card>
            </div>
        )
    }
}

EventCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventCard);