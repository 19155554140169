import React, { useContext, useEffect } from 'react'
import { uid } from 'uid'
import './Map.css'
import {
    GoogleMap,
    Marker,
    InfoWindow,
    LoadScript,
} from "@react-google-maps/api";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import "@reach/combobox/styles.css";
import { Modal } from '@material-ui/core';
import CreateLocationForm from '../CreateLocationForm/CreateLocationForm';
import { makeStyles } from '@material-ui/core/styles';
import CreateEventForm from '../CreateEventForm/CreateEventForm2';
import FilterForm from '../FilterForm/FilterForm';
import EventInfoWindow from './EventInfoWindow';
import LocationInfoWindow from './LocationInfoWindow';
import { AuthContext } from '../../AuthContext';
import { useHistory } from 'react-router-dom';
// const libraries = ["places"];

const options = {
    disableDefaultUI: true,
    zoomControl: true,
    gestureHandling: 'greedy',
};

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


export default function Map(props) {
    const context = useContext(AuthContext)
    const classes = useStyles();

    const [map, setMap] = React.useState(null);
    const [completeMapData, setCompleteMapData] = React.useState(null);
    const [tags, setTags] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [locations, setLocations] = React.useState([]);
    const [filterOptions, setFilterOptions] = React.useState({ match: "any", tags: [] })
    const [rightClickMenu, setRightClickMenu] = React.useState({
        top: 0,
        left: 0,
        anchorEl: null,
        open: false,
        timeoutHandle: null,
        mapPosition: { lat: 0, lng: 0 }
    });
    const [eventRightClickMenu, setEventRightClickMenu] = React.useState({
        top: 0,
        left: 0,
        anchorEl: null,
        open: false,
        timeoutHandle: null,
        event: null
    })
    const [locationRightClickMenu, setLocationRightClickMenu] = React.useState({
        top: 0,
        left: 0,
        anchorEl: null,
        open: false,
        timeoutHandle: null,
        location: null,
    });
    const [createLocationModal, setCreateLocationModal] = React.useState({
        open: false
    });
    const [createEventModal, setCreateEventModal] = React.useState({
        open: false,
        options: {
            position: null,
            location: null,
        }
    })
    const [editEventModal, setEditEventModal] = React.useState({
        open: false,
        event: null
    })
    const [editLocationModal, setEditLocationModal] = React.useState({
        open: false,
        location: null
    })

    const mapContainerStyle = {
        width: "100vw",
        height: `calc(100vh - ${document.getElementById('topbar') ? document.getElementById('topbar').offsetHeight : 100}px)`
    };

    //Update filter results any time we update our events, locations, or filter options
    useEffect(() => {
        filterData()
        console.log('Update!')
        // }, [locations, events, filterOptions])
    }, [filterOptions])

    //Get map details at load. Don't use normal update.
    useEffect(() => {
        context.api.get(`/api/map/${props.mapId}`, false)
            .then(data => {
                if (data.worked) {
                    setCompleteMapData(data.data)
                    console.log(data.data.details.options)
                }
            })

    }, [])
    const updateMapData = React.useCallback((mapUpdateData) => {
        console.log("mapUpdateData", mapUpdateData)
        const update = function (mapData) {
            const updatedEvents = mapData.details.events.map(event => {
                event.date = new Date(event.date)
                return event;
            });

            // setRawLocations(mapData.details.locations)
            // setRawEvents(updatedEvents)

            //update events and locations then filter them. Could turn them both into promises then filter once after both are finished.
            setEvents(updatedEvents.map((event) => { return { ...event, key: event._id } }), filterData)
            setLocations(mapData.details.locations.map((location) => { return { ...location, key: location._id } }), filterData)


            const nonUniqueTags = mapData.details.events.map(event => event.tags).reduce((a, x) => [...a, ...x], [])
            setTags([...new Set(nonUniqueTags)].sort());
            console.log(mapData);
            console.log('Updated Map Data')
        }

        if (mapUpdateData) {
            update(mapUpdateData)
        } else {
            context.api.get(`/api/map/${props.mapId}`, true)
                .then(data => {
                    if (data.worked) {
                        update(data.data)
                    }
                })
        }
    })

    const filterData = React.useCallback(() => {
        function setEventUnfiltered(prevState) {
            return { ...prevState, mapOptions_hidden: false }
        }
        function setEventFiltered(prevState) {
            return { ...prevState, mapOptions_hidden: true }
        }
        function shouldEventFiltered(event) {
            let shouldBeFiltered = false;
            if (filterOptions.tags.length > 0) {
                if (!event.tags) { //filtering for tags but event has none
                    shouldBeFiltered = true;
                } else {
                    if (filterOptions.match == "any") { //looking for any tag matches
                        if (!filterOptions.tags.some(tag => event.tags.includes(tag))) { //but not even some of the tags match
                            shouldBeFiltered = true;
                        }
                    } else if (filterOptions.match == "all") { //looking for all tags to be included
                        if (!filterOptions.tags.every(tag => event.tags.includes(tag))) { //but not all of the tags are there
                            shouldBeFiltered = true;
                        }
                    }
                }
            }
            if (filterOptions.dateMatching) { //Checking the dates if need be
                if (event.date < filterOptions.dateStart || event.date > filterOptions.dateEnd) { //if our date is outside the daterange: filter
                    shouldBeFiltered = true;
                }
            }
            return shouldBeFiltered
        }
        function shouldLocationGray(location) {
            let shouldBeGray = false;
            if (filterOptions.tags.length > 0 || filterOptions.dateMatching) { //Are we actually filtering?
                //If this location does not have some events that are still visible. We use shouldEventFiltered to prevent weird async issues.
                if (!events.some((event) => event.parentLocation === location._id && !shouldEventFiltered(event))) {
                    shouldBeGray = true;
                }
            }
            return shouldBeGray
        }
        function setLocationColor(location, color) {
            return { ...location, mapOptions_color: color }
        }

        //ADD DEFAULT_LOCATION_COLOR SOMEWHERE SOMETIME
        setEvents((prevEvent) => prevEvent.map((event) => shouldEventFiltered(event) ? setEventFiltered(event) : setEventUnfiltered(event))) //Creating some filtering flags in the events and locations objects
        setLocations((prevLocations) => prevLocations.map((location) => shouldLocationGray(location) ? setLocationColor(location, 'gray') : setLocationColor(location, 'blue')))
    })

    const mapRef = React.useRef();

    const onLoad = React.useCallback(function callback(map) {

        mapRef.current = map;

        // const bounds = new window.google.maps.LatLngBounds();
        // map.fitBounds(bounds);
        setMap(map);

        updateMapData();
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    const onFilterFormChange = React.useCallback((newFilterOptions) => {
        setFilterOptions(newFilterOptions)
    }, [])
    const onMapClick = React.useCallback((e) => {
        if (e.domEvent.metaKey || e.domEvent.ctrlKey) {
            onMapRightClick(e)
        }
    }, [])

    const onMapRightClick = React.useCallback((e) => {
        const doubleClickTimeout = 250

        rightClickMenu.timeoutHandle = setTimeout(() => {
            const mapPosition = {
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
            }
            setRightClickMenu({
                ...rightClickMenu,
                anchor: e.currentTarget,
                top: e.domEvent.clientY,
                left: e.domEvent.clientX,
                anchorEl: e.currentTarget,
                open: true,
                mapPosition: mapPosition

            });
        }, doubleClickTimeout);
    }, [rightClickMenu])

    const onMapZoomChanged = React.useCallback((e) => {
        //If the zoom has changed, user may have double right clicked. Clear any menus that are about to pop up.
        clearTimeout(rightClickMenu.timeoutHandle);
    }, [rightClickMenu])


    const onMapRightClickClose = React.useCallback((e) => {
        setRightClickMenu({
            ...rightClickMenu,
            open: false
        })
    }, [rightClickMenu])

    const createEventInfoWindowBody = React.useCallback((event) => {
        return <EventInfoWindow key={uid()} event={event} onEditEventClick={onMenuEditEvent(event)} />
    }, [])

    const handleEventInfoWindowClose = React.useCallback((event) => {
        return () => {
            setEvents((prevEvents) => prevEvents.map((prevEvent) => {
                if (prevEvent._id === event._id) {
                    return { ...prevEvent, mapOptions_infoWindowOpen: false }
                } else {
                    return { ...prevEvent }
                }
            }))
        }
    })


    const handleEventInfoWindowOpen = React.useCallback((event) => {
        return () => {
            setEvents((prevEvents) => prevEvents.map((prevEvent) => {
                if (prevEvent._id === event._id) {
                    return { ...prevEvent, mapOptions_infoWindowOpen: true }
                } else {
                    return { ...prevEvent }
                }
            }))
        }
    })

    const handleLocationInfoWindowClose = React.useCallback((location) => {
        return () => {
            setLocations((prevLocations) => prevLocations.map((prevLocation) => {
                if (prevLocation._id === location._id) {
                    return { ...prevLocation, mapOptions_infoWindowOpen: false }
                } else {
                    return { ...prevLocation }
                }
            }))
        }
    })

    const handleLocationInfoWindowOpen = React.useCallback((location) => {
        return () => {
            setLocations((prevLocations) => prevLocations.map((prevLocation) => {
                if (prevLocation._id === location._id) {
                    return { ...prevLocation, mapOptions_infoWindowOpen: true }
                } else {
                    return { ...prevLocation }
                }
            }))
        }
    })

    const onEventClick = React.useCallback((e, event) => {
        handleEventInfoWindowOpen(event)();
    }, []);

    const onEventRightClick = React.useCallback((e, event) => {
        const doubleClickTimeout = 250
        eventRightClickMenu.timeoutHandle = setTimeout(() => {
            setEventRightClickMenu({
                ...eventRightClickMenu,
                anchor: e.currentTarget,
                top: e.domEvent.clientY,
                left: e.domEvent.clientX,
                anchorEl: e.currentTarget,
                open: true,
                event: event
            });
        }, doubleClickTimeout);
    }, [])

    const onEventRightClickMenuClose = React.useCallback(() => {
        setEventRightClickMenu({ open: false })
    }, [])

    const onMenuCreateLocation = React.useCallback(() => {
        onMapRightClickClose()
        setCreateLocationModal({ open: true })
    });

    const onMenuEditLocation = React.useCallback((location) => {
        return () => {
            onLocationRightClickMenuClose()
            setEditLocationModal({ open: true, location: location })
        }
    });

    const onCloseCreateLocationModal = React.useCallback(() => {
        setCreateLocationModal({ open: false })
    });
    const onCloseCreateEventModal = React.useCallback(() => {
        setCreateEventModal({ ...createEventModal, open: false })
    });
    const onCloseEditEventModal = React.useCallback(() => {
        setEditEventModal({ ...editEventModal, open: false })
    });
    const onCloseEditLocationModal = React.useCallback(() => {
        setEditLocationModal({ ...editLocationModal, open: false })
    });

    const onCreateLocationFormSave = React.useCallback((data) => {
        updateMapData(data.updatedMap);
        setCreateLocationModal({ open: false })
    });

    const onEditLocationFormSave = React.useCallback((data) => {
        updateMapData(data.updatedMap);
        setEditLocationModal({ open: false })
    });

    const onCreateLocationFormDelete = React.useCallback((data) => {
        updateMapData(data.updatedMap);
        setEditLocationModal({ open: false })
    })

    const onCreateLocationFormCancel = React.useCallback(() => {
        console.log('cancel location!')
        setCreateLocationModal({ open: false })
    });
    const onEditLocationFormCancel = React.useCallback(() => {
        console.log('cancel edit location!')
        setEditLocationModal({ open: false })
    });

    const createLocationInfoWindowBody = React.useCallback((location) => {
        return (
            <LocationInfoWindow
                key={uid()}
                location={location}
                onEditLocationClick={onMenuEditLocation(location)}
                onAddLocationClick={onMenuCreateEvent({ location: location })}
            >
                {events.filter((event) => (!event.mapOptions_hidden && (event.parentLocation == location._id))).map((event) => createEventInfoWindowBody(event))}
            </LocationInfoWindow>
        )
    }, [events])

    const onLocationClick = React.useCallback((location) => {
        return (e) => {
            handleLocationInfoWindowOpen(location)();
        }
    });

    const onLocationRightClick = React.useCallback((location) => {
        return (e) => {
            const doubleClickTimeout = 250
            console.log(e);
            console.log('locationRightClickMenu', locationRightClickMenu);

            locationRightClickMenu.timeoutHandle = setTimeout(() => {
                console.log('creating location right click menu', e);
                setLocationRightClickMenu({
                    ...locationRightClickMenu,
                    anchor: e.currentTarget,
                    // top: e.pixel.y,
                    // left: e.pixel.x,
                    top: e.domEvent.clientY,
                    left: e.domEvent.clientX,
                    anchorEl: e.currentTarget,
                    open: true,
                    location: location

                });
                setTimeout(() => console.log("post set locationrightclickmenu", locationRightClickMenu), 1000)
            }, doubleClickTimeout);
        }
    });

    const onLocationRightClickMenuClose = React.useCallback(() => {
        setLocationRightClickMenu({
            ...locationRightClickMenu,
            open: false
        })
    })

    const onMenuCreateEvent = React.useCallback((options) => {
        return (e) => {
            onLocationRightClickMenuClose();
            createEventModal.options = options
            setCreateEventModal({ ...createEventModal, open: true })
            onMapRightClickClose()
        }
    })

    const onMenuEditEvent = React.useCallback((event) => {
        return (e) => {
            console.log("onMenuEdit Event", event);
            setEditEventModal({
                open: true,
            })
            setEventRightClickMenu({
                ...eventRightClickMenu,
                open: false,
                event: event
            })
        }
    })

    const onCreateEventFormSave = React.useCallback((result) => {
        //Moved to createEventForm
        updateMapData(result.updatedMap); //This is currently completely different from other similar
        setCreateEventModal({ open: false })
    });

    const onEditEventFormSave = React.useCallback((result) => {
        //Moved to createEventForm
        updateMapData(result.updatedMap); //This is currently completely different from other similar
        setEditEventModal({ open: false })
    });

    const onCreateEventFormDelete = React.useCallback((result) => {
        updateMapData(result.updatedMap); //This is currently completely different from other similar
        setEditEventModal({ open: false })
    })

    const onCreateEventFormCancel = React.useCallback((result) => {
        console.log('cancel create event!')
        if (result.refresh) {
            updateMapData()
        }
        setCreateEventModal({ open: false })
    })
    const onEditEventFormCancel = React.useCallback((result) => {
        console.log('cancel edit event form!')
        if (result.refresh) {
            updateMapData()
        }
        setEditEventModal({ open: false })
    })

    // if (loadError) return "Error loading maps";
    // if (!isLoaded) return "Loading map";

    return (
        <div className="Map">
            <div id="filter-form" className={classes.paper}>
                <FilterForm availableTags={tags} onChange={onFilterFormChange} />
            </div>
            <div id="view-toggle">
            </div>
            <Modal
                id="create-location-modal"
                open={createLocationModal.open}
                onClose={onCloseCreateLocationModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            // style={{
            //     top: `50%`,
            //     left: `50%`,
            //     transform: `translate(-50%, -50%)`,
            // }}
            >
                <div className={classes.paper}>
                    <CreateLocationForm
                        mapId={props.mapId}
                        onSave={onCreateLocationFormSave}
                        onCancel={onCreateLocationFormCancel}
                        position={rightClickMenu.mapPosition}
                    />
                </div>
            </Modal>
            <Modal
                id="create-event-modal"
                open={createEventModal.open}
                onClose={onCloseCreateEventModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            // style={{
            //     top: `50%`,
            //     left: `50%`,
            //     transform: `translate(-50%, -50%)`,
            // }}
            >
                <div className={classes.paper}>
                    <CreateEventForm
                        mapId={props.mapId}
                        locations={locations}
                        options={createEventModal.options}
                        onSave={onCreateEventFormSave}
                        onCancel={onCreateEventFormCancel}
                        availableTags={tags}
                    />
                </div>
            </Modal>
            <Modal
                id="edit-event-modal"
                open={editEventModal.open}
                onClose={onCloseEditEventModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            // style={{
            //     top: `50%`,
            //     left: `50%`,
            //     transform: `translate(-50%, -50%)`,
            // }}
            >
                <div className={classes.paper}>
                    <CreateEventForm
                        mapId={props.mapId}
                        locations={locations}
                        event={eventRightClickMenu.event}
                        onSave={onEditEventFormSave}
                        onCancel={onEditEventFormCancel}
                        onDelete={onCreateEventFormDelete}
                        availableTags={tags}
                    />
                </div>
            </Modal>
            <Modal
                id="edit-location-modal"
                open={editLocationModal.open}
                onClose={onCloseEditLocationModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            // style={{
            //     top: `50%`,
            //     left: `50%`,
            //     transform: `translate(-50%, -50%)`,
            // }}
            >
                <div className={classes.paper}>
                    <CreateLocationForm
                        mapId={props.mapId}
                        location={editLocationModal.location}
                        onSave={onEditLocationFormSave}
                        onCancel={onEditLocationFormCancel}
                        onDelete={onCreateLocationFormDelete}
                    />
                </div>
            </Modal>
            <Menu
                id="rightClickMenu"
                anchorReference="anchorPosition"
                anchorPosition={
                    rightClickMenu.top !== null && rightClickMenu.left !== null
                        ? { top: rightClickMenu.top, left: rightClickMenu.left }
                        : null
                }
                keepMounted
                open={rightClickMenu.open}
                onClose={onMapRightClickClose}
            >
                <MenuItem onClick={onMenuCreateLocation}>Create Location</MenuItem>
                <MenuItem onClick={onMenuCreateEvent({ position: rightClickMenu.mapPosition })}>Create Event</MenuItem>
            </Menu>
            <Menu
                id="locationRightClickMenu"
                anchorReference="anchorPosition"
                anchorPosition={
                    locationRightClickMenu.top !== null && locationRightClickMenu.left !== null
                        ? { top: locationRightClickMenu.top, left: locationRightClickMenu.left }
                        : null
                }
                keepMounted
                open={locationRightClickMenu.open}
                onClose={onLocationRightClickMenuClose}
            >
                <MenuItem onClick={onMenuCreateEvent({ location: locationRightClickMenu.location })}>Add Event</MenuItem>
                <MenuItem onClick={onMenuEditLocation(locationRightClickMenu.location)}>Edit Location</MenuItem>
            </Menu>
            <Menu
                id="eventRightClickMenu"
                anchorReference="anchorPosition"
                anchorPosition={
                    eventRightClickMenu.top !== null && eventRightClickMenu.left !== null
                        ? { top: eventRightClickMenu.top, left: eventRightClickMenu.left }
                        : null
                }
                keepMounted
                open={eventRightClickMenu.open}
                onClose={onEventRightClickMenuClose}
            >
                <MenuItem onClick={onMenuEditEvent(eventRightClickMenu.event)}>Edit Event</MenuItem>
            </Menu>

            <LoadScript googleMapsApiKey="AIzaSyC3SOGWT1mL3zndnkPL6nEe8xORvoXC_W4">
                {completeMapData &&
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        zoom={8}
                        center={completeMapData.details.center}
                        options={{ ...options, ...completeMapData.details.options }}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        onRightClick={onMapRightClick}
                        onClick={onMapClick}
                        onZoomChanged={onMapZoomChanged}
                    >
                        {events.filter((event) => !event.mapOptions_hidden && event.parentLocation == null).map((event) => {
                            const params = {}
                            if (event.textIcon) {
                                params.label = {
                                    text: event.textIcon,
                                    fontSize: '24px'
                                }
                                params.icon = {
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    scale: 5,
                                    fillOpacity: 0,
                                    strokeOpacity: 0,
                                }
                            }
                            return (
                                <Marker
                                    {...params}
                                    key={event._id}
                                    // label={{ text: "🐦", fontSize: '32px' }}
                                    // icon={{
                                    //     path: window.google.maps.SymbolPath.CIRCLE,
                                    //     scale: 0
                                    // }}
                                    position={event.position}
                                    // onClick={(e) => onEventClick(e, event)}
                                    onClick={(e) => {
                                        onEventClick(e, event);
                                    }}
                                    onRightClick={(e) => {
                                        onEventRightClick(e, event);
                                    }}
                                />
                            )
                        })}

                        {locations.map((location) => {
                            const params = {}
                            if (location.textIcon) {
                                params.label = {
                                    text: location.textIcon,
                                    fontSize: '24px'
                                }
                                params.icon = {
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    scale: 5,
                                    fillOpacity: 0,
                                    strokeOpacity: 0,
                                }
                            }
                            return (
                                <Marker
                                    key={location._id}
                                    position={location.position}
                                    icon={{
                                        path: window.google.maps.SymbolPath.CIRCLE,
                                        scale: 10,
                                        fillOpacity: 0,
                                        strokeColor: location.mapOptions_color ? location.mapOptions_color : "blue",
                                        strokeWeight: 5,
                                    }}
                                    // onClick={(e) => onEventClick(e, event)}
                                    onClick={onLocationClick(location)}
                                    onRightClick={onLocationRightClick(location)}
                                    {...params}
                                />
                            )
                        })}

                        {events.filter((event) => (event.mapOptions_infoWindowOpen && !event.mapOptions_hidden && event.position && event.position.lat && event.position.lng)).map((event) => (
                            <InfoWindow
                                position={event.position}
                                onCloseClick={handleEventInfoWindowClose(event)}
                                key={event._id}
                            >
                                <div>
                                    {createEventInfoWindowBody(event)}
                                </div>
                            </InfoWindow>
                        ))}

                        {locations.filter((location) => (location.mapOptions_infoWindowOpen && !location.mapOptions_hidden && location.position && location.position.lat && location.position.lng)).map((location) => (
                            <InfoWindow
                                position={location.position}
                                onCloseClick={handleLocationInfoWindowClose(location)}
                                key={location._id}
                            >
                                <div>
                                    {createLocationInfoWindowBody(location)}
                                </div>
                            </InfoWindow>
                        ))}

                    </GoogleMap>
                }
            </LoadScript>
        </div>
    )
};