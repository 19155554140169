import { Container, withStyles } from '@material-ui/core';
import React from 'react'
import { Link } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import MapList from './MapList';
import PropTypes from 'prop-types'


const styles = (theme) => ({
    createMapLink: {
        textDecoration: "none"
    }
});

class LandingPageAuthenticated extends React.Component {
    static contextType = AuthContext;

    state = {
        userMaps: []
    }

    componentDidMount() {
        console.log('Landing page mounted.')
        if (this.context.authenticated) {
            console.log('Getting maps for user.')
            this.context.api.get('/api/map')
                .then(data => {
                    console.log('Data returned', data)
                    if (data.worked) {
                        this.setState({ userMaps: data.maps })
                    }
                })
        }
    }

    render() {
        return (
            <Container>
                {this.context.authenticated &&
                    <div>
                        <h2>Maps</h2>
                        {this.state.userMaps.length === 0 &&
                            <p><Link to="/create-map">Create your first map</Link></p>
                        }
                        {this.state.userMaps.length > 0 &&
                            <>
                                <p><Link to="/create-map" className={this.props.classes.createMapLink}>Create a new map</Link></p>
                                <MapList maps={this.state.userMaps} />
                            </>
                        }
                    </div>
                }
            </Container>
        )
    }
}

LandingPageAuthenticated.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LandingPageAuthenticated);