import { Container, Link, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'

const styles = (theme) => ({

});

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <Container>
                <Paper style={{padding: 50}}>
                <Typography variant="h2" gutterBottom>
                    Privacy Policy
                </Typography>
                <Typography variant="subtitle1" paragraph>
                    Last updated 2/19/2021
                </Typography>

                <Typography variant="h5" gutterBottom>
                    Information we collect
                </Typography>
                <Typography variant="body1" paragraph>
                    Geologger may collect any information submitted to it. Information collected from account creation includes email. Information collected through use of the app includes submitted events, locations, and images. Further, we also collect information such as browser type, device type, ip addresses, duration of stay, whether or not a page loads, etc. This information is vital to providing and improving our service. Geologger also uses google analytics, for the purpose of analyzing site traffic, which may collect information during the course of your visit. For more information, or to learn how to opt out, please visit <Link href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</Link>.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Cookies
            </Typography>
                <Typography variant="body1" paragraph>
                    Geologger uses session cookies for maintaining user session across page loads and to link them with their account. Without these cookies, the website could not function.
                    Geologger uses google analytics, which uses cookies to track user actions. This helps Geologger track usage and build a better product. For more information, or to learn how to opt out, please visit <Link href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</Link>.
            </Typography>
                <Typography variant="h5" gutterBottom>
                    How we use your information
            </Typography>
                <Typography variant="body1" paragraph>
                    Information collected is used to:
                    <ul>
                        <li>Provide and maintain the service</li>
                        <li>Manage your registration and use of the service</li>
                        <li>To contact you via email, or other submitted method, with updates regarding website functionality, security, and similar.</li>
                        <li>To contact you via email, or other submitted method, with news, community highlights, or promotions unless you have opted out of such communications</li>
                        <li>For business transfers: We may use your information to evaluate or conduct a merger, divestiture, restructuring, reogranization, dissolution, or other sale or transfer of some or all of our assets, whether as going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal data held by us about our users is among assets transferred.</li>
                        <li>We may use your information for other purposes such as data analysis, identifying usage trends, and determining the effectiveness of promotional campaigns. This information helps us understand how Geologger is being used, what features to develop, demand for localizations, and generally helps us understand how to make a better product.</li>
                    </ul>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    How we share your information
            </Typography>
                <Typography variant="body1" paragraph>
                    <ul>
                        <li>We may share your information with service providers to monitor and analyze the use of Geologger</li>
                        <li>We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company</li>
                        <li>We may share your information with business partners to offer you certain products, services, or promotions</li>
                        <li>Information such as your profile name, date of account creation, and any earned badges may be shared with other users. Additionally, any maps, and their contents, explicitly made public, may also be shared with the public. Maps shared with individuals, will be shared with those individuals.</li>
                        <li>Your information may be shared as required by law, such as in the course of a criminal investigation.</li>
                        <li>We may disclose your information for any other purpose with your consent.</li>
                    </ul>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    How we transfer your personal information
                </Typography>
                <Typography variant="body1" paragraph>
                    Your information, including personal information, may be transferred between servers and computers not in your state, province, country, or governmental jurisdiction. Privacy laws may differ from those in your jurisdiction. Consenting to this privacy policy and submitting any such information to this website represents consent to such transfers. We will take all reasonably necessary steps to ensure that your data is treated securely and in accordance with this privacy policy. No data will be transferred to any organization or country unless they can securely handle your information.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    How we retain your information
                </Typography>
                <Typography variant="body1" paragraph>
                    Geologger, as of this time, has no intent of ever collecting information such as credit card numbers, social security numbers, or similar highly sensitive information. Payment will be processed through a third party to avoid such data collection. Information related to the core features of the website, such as maps, events, and locations, will be deleted when the user decides to delete them. Information such as email will be kept as long as the account exists. Information related to usage will be kept indefinitely for analytical purposes.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    How we disclose your personal data
                </Typography>
                <Typography variant="h6">
                    Business Transactions
                </Typography>
                <Typography variant="body1" paragraph>
                    If this website is involved in a merger, acquisition, or asset sale, your personal information may be transferred. We will provide notice before your personal data is transferred and becomes subject to a different privacy policy.
                </Typography>
                <Typography variant="h6">
                    Law Enforcement
                </Typography>
                <Typography variant="body1" paragraph>
                    If required by law, or in response to a valid request by public authorities, Geologger may required to disclose your information
                </Typography>
                <Typography variant="h6">
                    Good Faith Legal
                </Typography>
                <Typography variant="body1" paragraph>
                    Geologger may disclose your personal information in the good faith belief that such action is necessary to:
                    <ul>
                        <li>Comply with legal obligation</li>
                        <li>Protect and defend the rights or property of Geologger</li>
                        <li>Prevent or investigate possible wrongdoing in connection with the service</li>
                        <li>Protect the personal safety of users of Geologger or the public</li>
                        <li>Protect against legal liability</li>
                    </ul>
                </Typography>
            <Typography variant="h5" gutterBottom>
            How we secure your data
            </Typography>
            <Typography variant="body1" paragraph>
            Geologger uses salt and hashing techniques to keep your passwords safe. Additionally, user connections to geologger are encrypted. It is important to remember however, that there is no such thing as 100% secure; as such, we cannot guarantee your information will never be stolen; however, within a reasonable time period upon discovery, we will inform you if we believe your information may have been stolen.
            </Typography>
            <Typography variant="h5" gutterBottom>
            Children’s policy
            </Typography>
            <Typography variant="body1" paragraph>
            Geologger is not intended for people under the age of 13.
            </Typography>
            <Typography variant="h5" gutterBottom>
            External Links
            </Typography>
            <Typography variant="body1" paragraph>
            Some links may lead to websites outside of Geologger: Geologger is not responsible for the policies or content on websites not operated by Geologger.
            </Typography>
            <Typography variant="h5" gutterBottom>
            Third Party Services
            </Typography>
            <Typography variant="h6">
            Google Analytics
            </Typography>
            <Typography variant="body1" paragraph>
            Geologger uses Google Analytics to track website traffic and make insights. Google Analytics may collect information about you during the course of your visit. Google Analytics also uses tracking cookies to facilitate this process. If you would like to learn more, as well as how to opt out, please visit: <Link href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</Link>.
            </Typography>
            <Typography variant="h5" gutterBottom>
            Privacy Changes
            </Typography>
            <Typography variant="body1" paragraph>
            Geologger may update its privacy policy from time to time. Updates will be effective when they are posted to this page. Prior notice will be given via an in app notification or email.
            </Typography>
            <Typography variant="h5" gutterBottom>
            Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
            If you have any questions or comments, please contact us using the form at <Link href="/contact">geologger.co/contact</Link>.
            </Typography>
            </Paper>
            </Container>
        )
    }
}

PrivacyPolicy.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrivacyPolicy);