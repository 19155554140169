import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React, { useContext } from 'react';
import { AuthContext } from '../../AuthContext';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        Geologger
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {
  const authContext = useContext(AuthContext)
  const classes = useStyles();
  const [state, setState] = React.useState({
    // emailError: false,
    // passwordError: false,
    submitDisabled: false
  });

  function handleSubmit(e) {
    e.preventDefault();
    setState({ ...state, submitDisabled: true }) //disable submit button after it is pressed.
    // fetch('/api/login', {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(state)
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log(data);
    //     if (data.worked) {
    //       //Redirect
    //       props.history.push("/")
    //     } else if (data.info.name == "IncorrectPasswordError") {
    //       //This doesn't update the error values I have no idea why.
    //       // setState({
    //       //   ...state,
    //       //   emailError: true,
    //       //   passwordError: true
    //       // })
    //     }
    //   })
    //   .catch(error => console.error("Error:", error))
    //   .finally(() => setState({ ...state, submitDisabled: false })) //re-enable submit button when everything is done.
    authContext.login({ ...state })
      .then(data => {
        console.log(data)
        if(data.worked === true) {
          props.history.push("/")
        }
      })
      .catch(error => console.error(error))
      .finally(() => setState({ ...state, submitDisabled: false })) //re-enable submit button when everything is done.

  }

  function handleChange(key) {
    return (event) => {
      const value = event.target.type == "checkbox" ? event.target.checked : event.target.value
      setState({
        ...state,
        [key]: value
      });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          {/* <RoomIcon /> */}
          <LockOutlinedIcon />
        </Avatar>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            onChange={handleChange("email")}
            variant="outlined"
            margin="normal"
            // error={state.emailError}
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            onChange={handleChange("password")}
            variant="outlined"
            margin="normal"
            // error={state.passwordError}
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/* <FormControlLabel
            onChange={handleChange("remember")}
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={state.submitDisabled}
          >
            Sign In
          </Button>
          <Grid container>
            {/* <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid> */}
            <Grid item>
              <Link href="sign-up" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
