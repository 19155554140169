import React from 'react'
import MapCard from './MapCard'

export default class MapList extends React.Component {
    render() {
        return (
            <div>
                {
                    this.props.maps.map((map) => {
                        return (
                            <MapCard map={map} />
                        )
                    })
                }
            </div>
        )
    }
}