import React from 'react';
import { FormControl, Select, TextField, InputLabel, Button, Modal, Paper, Container } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ImageDropzone from '../ImageUpload/ImageDropzone';
import EventImageGallery from './EventImageGallery'
import RUG from 'react-upload-gallery'
import "react-upload-gallery/dist/style.css";
import "./CreateEventForm.css"
import { AuthContext } from '../../AuthContext';
import Picker from 'emoji-picker-react'


const styles = theme => ({
    root: {
        width: 550,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
    description: {
        minHeight: 50
    },
    formRoot: {
        maxHeight: "90vh",
        maxWidth: "600px",
        overflowY: "auto",
        overflowX: "hidden"
    },
    deleteButton: {
        float: "right"
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        '& > div > div:first-child': {
            visible: false,
            height: "0px",
            width: "0px",
            position: "absolute",
        }
    },
});

/**
 * Date should save to and be loaded from UTC.
 */
class CreateEventForm extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        if (props.event) {
            const date = new Date(props.event.date);
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
            this.state = {
                _id: props.event._id,
                date: date,
                title: props.event.title,
                locationContext: props.event.locationContext,
                parentLocation: props.event.parentLocation ? props.event.parentLocation : "custom",
                description: props.event.description,
                tags: props.event.tags ? props.event.tags : [],
                images: props.event.images ? props.event.images : [],
                textIcon: props.event.textIcon ? props.event.textIcon : "",
            }
            if (props.event.position) {
                this.state.lat = props.event.position.lat;
                this.state.lng = props.event.position.lng;
            }
        } else {
            const initialDate = new Date();
            initialDate.setHours(0, 0, 0, 0)
            this.state = {
                date: initialDate,
                title: "",
                locationContext: "",
                parentLocation: props.options.location ? props.options.location._id : "custom",
                lat: props.options.position ? props.options.position.lat : null,
                lng: props.options.position ? props.options.position.lng : null,
                description: null,
                tags: [],
                images: [],
                textIcon: "",
            }
        }
        // this.state.autoProcessQueue = false
        this.state.autoProcessQueue = !!props.event
        this.dropzoneRef = React.createRef();
        this.state.mapRequiresUpdate = false //set to true if we upload pics prior to saving.
        this.state.editImageOrderModalOpen = false
        this.state.editMapIconModalOpen = false
        this.state.errorMessage = ""
        this.state.saveDisabled = false
        this.state.errors = {}

        console.log("SSSSSSSSSSS", this.state);

        this.onDateChange = this.onDateChange.bind(this);
        this.onTimeChange = this.onTimeChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.saveToServer = this.saveToServer.bind(this);
        this.attemptReturnMapOnSave = this.attemptReturnMapOnSave.bind(this)
        this.onImagesUpload = this.onImagesUpload.bind(this)



        console.log("options", props.options)
    }
    onDateChange(date, value) {
        console.log("date", date);
        console.log("value", value);
        if (date && !isNaN(date)) {
            date.setHours(this.state.date.getHours())
            date.setMinutes(this.state.date.getMinutes())
            this.setState({
                date: date
            });
        }
    }
    onTimeChange(date, value) {
        if (date && !isNaN(date)) {
            date.setDate(1) //We do this to prevent any weird overflows (31->1; leap years)
            date.setFullYear(this.state.date.getFullYear())
            date.setMonth(this.state.date.getMonth())
            date.setDate(this.state.date.getDate())
            this.setState({ date: date });
        }
    }
    handleChange(key) {
        return (event) => { this.setState({ [key]: event.target.value }); }
    }
    handleTagsChange(e, value) {
        this.setState({ tags: value })
    }
    validate() {
        let valid = true;
        const errors = {}
        if (!this.state.title.trim()) {
            valid = false
            errors["title"] = "Title is required"
        }
        if (this.state.parentLocation === "custom") {
            if (!this.state.lat || isNaN(this.state.lat)) {
                valid = false
                errors["lat"] = "Latitude is required if no parent location is specified"
            }
            if (!this.state.lng || isNaN(this.state.lng)) {
                valid = false
                errors["lng"] = "Longitude is required if no parent location is specified"
            }
        }
        this.setState({ errors: errors })
        console.log("valid", valid)
        return valid;
    }
    handleSubmit() {
        if (this.validate()) {
            //An event does not contain its parent location. It may contain coordinates.
            let position
            if (this.state.lat === null || this.state.lng === null) {
                position = null;
            } else {
                position = {
                    lat: this.state.lat,
                    lng: this.state.lng,
                };
            }
            const date = new Date(this.state.date);
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
            let newEvent = {
                parentLocation: this.state.parentLocation === "custom" ? null : this.state.parentLocation,
                position: position,
                title: this.state.title,
                locationContext: this.state.locationContext,
                date: date,
                description: this.state.description,
                tags: this.state.tags,
                images: this.state.images,
                textIcon: this.state.textIcon,
            }
            if (this.state._id) {
                newEvent._id = this.state._id
            }
            this.saveToServer(newEvent);
        }
    }
    handleDelete() {
        if (window.confirm("Are you sure you would like to delete this event?")) {
            if (this.state._id) {
                this.context.api.delete(`/api/map/${this.props.mapId}/event/${this.state._id}`, null, true)
                    .then(data => {
                        if(data.worked) {
                            this.props.onDelete(data)
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }
        }
    }
    handleCancel() {
        this.props.onCancel({ refresh: this.state.mapRequiresUpdate });
    }
    saveToServer(event) {
        const fetchOptions = {}
        if (event._id) { //Are we editing or creating? _id means editing
            fetchOptions.url = `/api/map/${this.props.mapId}/event/${event._id}`
            fetchOptions.method = 'PUT'
        } else { //no _id means editing
            fetchOptions.url = '/api/map/event'
            fetchOptions.method = 'POST'
        }
        this.context.api.fetch(fetchOptions.method, fetchOptions.url, {
            mapId: this.props.mapId,
            event: event
        }, true)
            .then(data => {
                console.log('Success:', data);
                if (data.worked) {
                    this.attemptReturnMapOnSave(data.eventId)
                } else {
                    if (data.err && data.err.error && data.err.error[0]) {
                        this.setState({ errorMessage: data.err.error[0].message })
                    }
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    attemptReturnMapOnSave(eventId) {
        const finish = () => {
            fetch(`/api/map/${this.props.mapId}`)
                .then(response => response.json())
                .then(data => {
                    if (data.worked) {
                        this.props.onSave({ updatedMap: data.data });
                    }
                })
        }
        console.log("REF", this.dropzoneRef)
        //Now that we are ready to end, set the dropzone to automatically
        //finish everything
        this.dropzoneRef.current.state.dropzone.on("queuecomplete", finish)
        //Do we have a queue though? If not just finish now.
        if (this.dropzoneRef.current.state.dropzone.getQueuedFiles().length <= 0) {
            finish()
            return
        }
        //If we do have a queue, set the event Id to make sure it's going
        // this.dropzoneRef.setEventId(eventId)
        this.dropzoneRef.current.state.dropzone.on("sending", function (file, xhr, formData) {
            formData.append("eventId", eventId)
        })
        this.dropzoneRef.current.state.dropzone.options.autoProcessQueue = true;
        this.dropzoneRef.current.state.dropzone.processQueue();
        //When the queue finishes it will trigger the .on event
    }
    //When an image is uploaded, we need to add it to the event so we don't remove it upon saving.
    onImagesUpload(images) {
        this.setState((current) => {
            return {
                images: [...current.images, ...images],
                mapRequiresUpdate: true,
            }
        })

    }
    render() {
        const mapIcon = this.state.textIcon ? this.state.textIcon : <img height={24} alt="default event marker" src="/public/map_default_marker.png" />
        return (
            <div className={this.props.classes.formRoot}>
                <Paper>
                    <Container>
                        {/* Image sorting modal */}
                        <Modal
                            id="edit-event-modal"
                            open={this.state.editImageOrderModalOpen}
                            onClose={() => this.setState({ editImageOrderModalOpen: false })}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            <div className={this.props.classes.paper} >
                                <p>Sort</p>
                                <RUG
                                    style={{ overflow: 'auto', width: "100vw" }}
                                    action=""
                                    onChange={(images) => this.setState({ images: images.map((image) => image.image) })}
                                    initialState={this.state.images.map((image) => {
                                        return {
                                            image: image,
                                            // name: "A",
                                            // size: "12kb",
                                            source: `/api/image/${image.image}`
                                        }
                                    })} />
                                <Button onClick={() => this.setState({ editImageOrderModalOpen: false })}>Done</Button>
                            </div>
                        </Modal>
                        <Modal
                            open={this.state.editMapIconModalOpen}
                            onClose={() => this.setState({ editMapIconModalOpen: false })}
                        >
                            <div>
                                <Paper style={{ width: 280 }}>
                                    <Picker native onEmojiClick={(event, chosenEmoji) => {
                                        console.log(event)
                                        console.log(chosenEmoji)
                                        this.setState({ textIcon: chosenEmoji.emoji, editMapIconModalOpen: false })
                                    }} />
                                    <Button onClick={() => this.setState({ textIcon: "", editMapIconModalOpen: false })}>Clear</Button>
                                </Paper>
                            </div>
                        </Modal>
                        <h2>{this.state._id ? "Edit Event" : "Create Event"}</h2>
                        <Button
                            variant="outlined"
                            style={{ marginBottom: 10 }}
                            onClick={() => this.setState({ editMapIconModalOpen: true })}
                        >
                            {mapIcon}
                        </Button>
                        <form noValidate autoComplete="off">
                            <div>
                                <TextField
                                    label="Title"
                                    defaultValue={this.state.title}
                                    onBlur={this.handleChange("title")}
                                    error={!!this.state.errors["title"]}
                                />
                            </div>

                            {/* <div>
                        <TextField label="Location Context" defaultValue={this.state.locationContext} onBlur={this.handleChange("locationContext")} />
                    </div> */}
                            <div>
                                <FormControl>
                                    <InputLabel shrink htmlFor="location">Location</InputLabel>
                                    <Select
                                        native
                                        inputProps={{
                                            name: "location",
                                            id: "location"
                                        }}
                                        value={this.state.parentLocation}
                                        onChange={this.handleChange("parentLocation")}
                                    >
                                        <option key="custom" aria-label="Custom" value="custom">Custom</option>
                                        {this.props.locations.map(location => (
                                            <option key={location._id} aria-label={location.name} value={location._id}>{location.name}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            {this.state.parentLocation === "custom" &&
                                <div>
                                    <TextField
                                        label="Location Latitude"
                                        onBlur={this.handleChange("lat")}
                                        defaultValue={this.state.lat}
                                        error={!!this.state.errors["lat"]}
                                    />
                                    &nbsp;
                                    <TextField
                                        label="Location Longitude"
                                        onBlur={this.handleChange("lng")}
                                        defaultValue={this.state.lng}
                                        error={!!this.state.errors["lng"]}
                                    />
                                </div>
                            }
                            <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        format="MM/dd/yyyy"
                                        value={this.state.date}
                                        onChange={this.onDateChange}
                                        minDate={(new Date()).setFullYear(0)}
                                    />
                                    <KeyboardTimePicker
                                        value={this.state.date}
                                        onChange={this.onTimeChange}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div>
                                <TextField
                                    InputProps={{ classes: { input: this.props.classes.description } }}
                                    fullWidth
                                    multiline
                                    label="Description"
                                    defaultValue={this.state.description}
                                    onBlur={this.handleChange("description")}
                                />
                            </div>
                            <div className={this.props.classes.root}>
                                <Autocomplete
                                    id="tags"
                                    multiple
                                    freeSolo
                                    autoSelect
                                    blurOnSelect
                                    filterSelectedOptions
                                    value={this.state.tags}
                                    onChange={this.handleTagsChange}
                                    options={[...this.props.availableTags]}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="tags"
                                            placeholder="tags"
                                        />
                                    )}
                                />
                            </div>
                            {this.state.images.length > 0 &&
                                <div>
                                    <EventImageGallery images={this.state.images} />
                                    <Button color="primary" size="small" onClick={() => this.setState({ editImageOrderModalOpen: true })}>[Edit Images]</Button>
                                </div>
                            }
                            <div>
                                <p>Add Images (optional)</p>
                                <ImageDropzone
                                    mapId={this.props.mapId}
                                    eventId={this.state._id}
                                    postUrl="/api/map/event/image"
                                    autoProcessQueue={this.state.autoProcessQueue}
                                    ref={this.dropzoneRef}
                                    onImagesUpload={this.onImagesUpload}
                                />
                            </div>
                            <div>
                                {this.state.errorMessage &&
                                    <p>{this.state.errorMessage}</p>
                                }
                                {Object.values(this.state.errors).map((error) => <p>{error}</p>)}
                            </div>
                            <div>
                                <Button onClick={this.handleCancel} color="default">
                                    Cancel
                                </Button>
                                <Button onClick={this.handleSubmit} color="primary">
                                    Save
                                </Button>
                                {this.state._id &&
                                    <Button className={this.props.classes.deleteButton} onClick={this.handleDelete} color="secondary">
                                        Delete
                                    </Button>
                                }
                            </div>
                        </form>
                    </Container>
                </Paper>
            </div>
        );
    }
}

CreateEventForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateEventForm)