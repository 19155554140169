import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { AuthContext } from '../../AuthContext';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        Geologger
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  zIndexBost: {
    zIndex: 1,
  },
}));

export default function SignUpCore(props) {
  const authContext = useContext(AuthContext)
  const classes = useStyles();
  const [state, setState] = React.useState({
    submitDisabled: false
  });
  const [errors, setErrors] = React.useState({})

  function handleSubmit(e) {
    e.preventDefault();
    if (validate()) {
      setState({ ...state, submitDisabled: true })
      console.log(state)
      fetch('/api/register', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(state)
      })
        .then(response => response.json())
        .then(data => {
          console.log("registration data", data)
          if (data.worked) {
            authContext.loginCheck();
            //   props.history.push("/")
          } else if (data.error.name === "UserExistsError") {
            setErrors({ responseError: "Email is already registered" })
          } else if (data.error.name === "ProfileNameExistsError") {
            setErrors({ responseError: "Profile name is already in use" })
          } else {
            setErrors({ responseError: "Could not register with server" })
          }
        })
        .catch (error => console.error("Error:", error))
        .finally(() => setState({ ...state, submitDisabled: false }))
  }
}

function validate() {
  const errs = {}
  if (!state.profileName || !state.profileName.trim()) {
    errs["profileName"] = "Profile name is required"
  } else {
    if (state.profileName.trim().length < 3) {
      errs["profileName"] = "Profile name must be at least 3 characters"
    }
  }
  if (!state.email || !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i.test(state.email)) {
    //email must exist and be an email
    errs["email"] = "Valid email is required"
  }
  if (!state.password) {
    errs["password"] = "Password is required"
  }
  console.log(errs)
  setErrors(errs)
  return Object.values(errs).length === 0
}

function handleChange(key) {
  return (event) => {
    const value = event.target.type == "checkbox" ? event.target.checked : event.target.value
    setState({
      ...state,
      [key]: value
    });
  }
}

return (
  <Container component="main" maxWidth="xs">
    <CssBaseline />
    <div className={classes.paper}>
      <Typography className={classes.zIndexBost} component="h1" variant="h5">
        Sign up
        </Typography>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              onChange={handleChange("profileName")}
              value={state["profileName"]}
              variant="outlined"
              required
              fullWidth
              id="profileName"
              label="Profile Name"
              name="profileName"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={handleChange("email")}
              value={state["email"]}
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={handleChange("password")}
              value={state["password"]}
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
          </Grid>
          <Grid item xs={12} className={classes.zIndexBost}>
            <FormControlLabel
              onChange={handleChange("allowExtraEmails")}
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              label="I want to receive inspiration, marketing promotions and updates via email."
            />
          </Grid>
          <Grid item xs={12} className={classes.zIndexBost}>
            <div>By signing up, you agree to our <Link target="_blank" href="/privacy">privacy policy</Link>, which includes our cookie policy.</div>
          </Grid>
        </Grid>
        {Object.values(errors).map((error) => <p style={{ position: "relative" }}>{error}</p>)}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={state.submitDisabled}
        >
          Sign Up
          </Button>
      </form>
    </div>
  </Container>
);
}
