import { Button, Checkbox, Container, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core'
import React from 'react'
import { uid } from 'uid'
import { ArrowUpward, ArrowDownward, Delete as DeleteIcon } from '@material-ui/icons';
import CustomField from './CustomField';
import { AuthContext } from '../../../AuthContext';


export default class CustomFieldForm extends React.Component {
    static contextType = AuthContext;

    state = {
        fieldName: "",
        label: "",
        type: "",
        textOptions: {
            placeholder: "",
            defaultValue: "",
            multiline: false,
        },
        dropdownOptions: {
            menuItems: [
                { id: uid(), label: "", hidden: false },
            ],
        },
        checkboxOptions: {
            defaultValue: false
        },
        dateOptions: {},

        saveStatus: ""
    }

    validate = () => {
        if (!this.state.fieldName.trim()) {
            this.setState({ saveStatus: "Field name required" })
            return false
        }
        if (!this.state.label.trim()) {
            this.setState({ saveStatus: "Label required" })
            return false
        }
        if (!this.state.type) {
            this.setState({ saveStatus: "Please choose a field type" })
            return false
        }
        switch (this.state.type) {
            case "dropdown":
                const dropdownLength = this.state.dropdownOptions.menuItems.length
                if (dropdownLength < 1) {
                    this.setState({ saveStatus: "Dropdowns require at least 1 menu item" })
                    return false
                }

        }
        return true
    }

    save = () => {
        const mapId = this.props.match.params.mapId
        if (this.validate()) {
            this.setState({ saveStatus: "Saving..." })
            var options = {}
            switch (this.state.type) {
                case "text":
                    options = this.state.textOptions
                    break
                case "dropdown":
                    options = this.state.dropdownOptions
                    break
                case "checkbox":
                    options = this.state.checkboxOptions
                    break
                case "date":
                    options = this.state.dateOptions
                    break
            }
            const newField = {
                name: this.state.fieldName,
                type: this.state.type,
                label: this.state.label,
                options: options,
            }
            const method = newField._id ? 'PUT' : 'POST'
            const url = newField._id ? `/api/map/${mapId}/field/${newField._id}` : '/api/map/field'

            this.context.api.fetch(method, url, {
                mapId: mapId,
                field: newField
            }, true)
                .then(data => {
                    if (data.worked) {
                        this.setState({ saveStatus: "Saved." })
                        console.log("data", data)
                        // this.props.onSave(data)
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    this.setState({ saveStatus: "Something went wrong" })
                });
        }
    }

    handleChange = (key) => {
        return (e) => {
            let value;
            if (e.target.type === "checkbox") {
                value = e.target.checked
            } else {
                value = e.target.value
            }
            this.setState({ [key]: value })
            console.log(e)
        }
    }

    handleOptionsChange = (option, key) => {
        const optionKey = option + "Options"
        return (e) => {
            let value;
            if (e.target.type === "checkbox") {
                value = e.target.checked
            } else {
                value = e.target.value
            }
            this.setState({
                [optionKey]: {
                    ...this.state[optionKey],
                    [key]: value
                }
            })
            console.log(e)
        }
    }

    handleTextPlaceholderChange = (e) => {
        return this.setState({ textOptions: { ...this.state.textOptions, placeholder: e.target.value } })
    }

    setMenuItems = (menuItems) => {
        this.setState({
            dropdownOptions: {
                ...this.state.dropdownOptions,
                menuItems: menuItems,
            }
        })
    }

    handleAddMenuItem = (e) => {
        this.setState({
            dropdownOptions: {
                ...this.state.dropdownOptions,
                menuItems: [...this.state.dropdownOptions.menuItems, { id: uid(16), label: "", hidden: false }]
            }
        })
    }

    handleRemoveMenuItem = (id) => {
        return () => {
            this.setState({
                dropdownOptions: {
                    ...this.state.dropdownOptions,
                    menuItems: this.state.dropdownOptions.menuItems.filter((menuItem) => menuItem.id !== id)
                }
            })
        }
    }

    handleOnChangeMenuItem = (id) => {
        return (e) => {
            this.setMenuItems(this.state.dropdownOptions.menuItems.map((menuItem) => {
                if (menuItem.id === id) {
                    let newMenuItem = { ...menuItem }
                    newMenuItem.label = e.target.value
                    return newMenuItem
                } else {
                    return menuItem
                }
            }))
        }
    }

    handleHideMenuItem = (id) => {
        return (e) => {
            this.setMenuItems(this.state.dropdownOptions.menuItems.map((menuItem) => {
                if (menuItem.id === id) {
                    return { ...menuItem, hidden: e.target.checked }
                } else {
                    return menuItem
                }
            }))
        }
    }

    arrayIndexSwap(array, index1, index2) {
        let arr = [...array]
        const temp = arr[index1]
        arr[index1] = arr[index2]
        arr[index2] = temp
        return arr
    }

    handleMoveMenuItemUp = (id) => {
        return () => {
            const itemPosition = this.state.dropdownOptions.menuItems.findIndex((menuItem) => menuItem.id === id)
            if (itemPosition === 0) { //nowhere to move
                return
            } else { //swap item with item above
                this.setMenuItems(this.arrayIndexSwap(this.state.dropdownOptions.menuItems, itemPosition, itemPosition - 1))
            }
        }
    }

    handleMoveMenuItemDown = (id) => {
        return () => {
            const arrayLength = this.state.dropdownOptions.menuItems.length
            const itemPosition = this.state.dropdownOptions.menuItems.findIndex((menuItem) => menuItem.id === id)
            if (itemPosition === (arrayLength - 1)) { //nowhere to move
                return
            } else { //swap item with item below
                this.setMenuItems(this.arrayIndexSwap(this.state.dropdownOptions.menuItems, itemPosition, itemPosition + 1))

            }
        }
    }

    render() {
        return (
            <Paper>
                <Container>
                    <h2>Create Custom Field</h2>
                    <form>
                        <div>
                            <TextField value={this.state.fieldName} onChange={this.handleChange('fieldName')} label="Field Name" />
                        </div>
                        <div>
                            <TextField value={this.state.label} onChange={this.handleChange('label')} label="Label" />
                        </div>
                        <div>
                            <FormControl>
                                <InputLabel id="field-type-label">Field Type</InputLabel>
                                <Select
                                    style={{ width: 100 }}
                                    labelId="field-type-label"
                                    id="field-type"
                                    onChange={this.handleChange('type')}
                                    value={this.state.type}
                                >
                                    <MenuItem value="text">Text</MenuItem>
                                    <MenuItem value="dropdown">Dropdown</MenuItem>
                                    <MenuItem value="checkbox">Checkbox</MenuItem>
                                    <MenuItem value="date">Date</MenuItem>
                                </Select>
                            </FormControl>
                            {this.state.type === "text" &&
                                <div>
                                    <p>Options</p>
                                    <div>
                                        <div>
                                            <TextField value={this.state.textOptions.defaultValue} onChange={this.handleOptionsChange('text', 'defaultValue')} label="Default Value" />
                                        </div>
                                        <div>
                                            <TextField value={this.state.textOptions.placeholder} onChange={this.handleOptionsChange('text', 'placeholder')} label="Placeholder" />
                                        </div>
                                        <div>
                                            <FormControlLabel
                                                label="multiline"
                                                control={<Checkbox value={this.state.textOptions.multiline} onChange={this.handleOptionsChange('text', 'multiline')} />}
                                            />
                                        </div>
                                        <div style={{ marginBottom: 20 }}>
                                            <p style={{ marginBottom: 0 }}>Sample</p>
                                            <CustomField field={{
                                                label: this.state.label,
                                                type: "text",
                                                options: this.state.textOptions,
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.type === "dropdown" &&
                                <div>
                                    <p>Options</p>
                                    {this.state.dropdownOptions.menuItems.map((menuItem) => {
                                        return (
                                            <div>
                                                <TextField
                                                    key={menuItem.id}
                                                    value={menuItem.label}
                                                    label="Menu Item"
                                                    onChange={this.handleOnChangeMenuItem(menuItem.id)}
                                                />
                                                <Button onClick={this.handleMoveMenuItemUp(menuItem.id)} style={{ maxWidth: 25, minWidth: 25 }}><ArrowUpward /></Button>
                                                <Button onClick={this.handleMoveMenuItemDown(menuItem.id)} style={{ maxWidth: 25, minWidth: 25 }}><ArrowDownward /></Button>
                                                <FormControlLabel
                                                    style={{ marginLeft: 5 }}
                                                    label="hide item"
                                                    control={<Checkbox value={menuItem.hidden} onChange={this.handleHideMenuItem(menuItem.id)} />}
                                                />
                                                <Button onClick={this.handleRemoveMenuItem(menuItem.id)}><DeleteIcon />Delete</Button>
                                            </div>
                                        )
                                    })}
                                    <div>
                                        <Button onClick={this.handleAddMenuItem}>+ Add Menu Item</Button>
                                    </div>
                                    <div style={{ marginBottom: 20 }}>
                                        <p style={{ marginBottom: 0 }}>Sample</p>
                                        <CustomField field={{
                                            label: this.state.label,
                                            type: "dropdown",
                                            options: this.state.dropdownOptions,
                                        }} />
                                    </div>
                                </div>
                            }
                            {this.state.type === "checkbox" &&
                                <div>
                                    <p>Options</p>

                                    <FormControlLabel
                                        label="Default Value"
                                        control={<Checkbox checked={this.state.checkboxOptions.defaultValue} onChange={this.handleOptionsChange('checkbox', 'defaultValue')} />}
                                    />
                                    <div style={{ marginBottom: 20 }}>
                                        <p style={{ marginBottom: 0 }}>Sample</p>
                                        <CustomField field={{
                                            label: this.state.label,
                                            type: "checkbox",
                                            options: this.state.checkboxOptions,
                                        }} />
                                    </div>
                                </div>
                            }
                            {this.state.type === "date" &&
                                <div>
                                    <p>Options</p>
                                    <div>
                                        <div>
                                            <TextField
                                                type="date"
                                                label="Default Value"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={this.state.dateOptions.defaultValue}
                                                onChange={this.handleOptionsChange('date', 'defaultValue')}
                                            />
                                        </div>
                                        <div style={{ marginBottom: 20 }}>
                                            <p style={{ marginBottom: 0 }}>Sample</p>
                                            <CustomField field={{
                                                label: this.state.label,
                                                type: "date",
                                                options: this.state.dateOptions,
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {this.state.saveStatus &&
                            <div>
                                <p>{this.state.saveStatus}</p>
                            </div>
                        }
                        <div>
                            <Button onClick={this.save} color="primary">Save</Button>
                            <Button color="secondary">Cancel</Button>
                        </div>
                    </form>
                </Container>
            </Paper>
        )
    }
}