import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import React from 'react'
import { uid } from 'uid'

/**
 * Requires props.field
 */
export default class CustomField extends React.Component {
    constructor(props) {
        super(props)
        if (this.props.field.options) {
            switch (this.props.field.type) {
                case "text":
                    this.state.value = this.props.field.options.defaultValue
                    break
                case "dropdown":
                    break
                case "checkbox":
                    this.state.value = this.props.field.options.defaultValue
                    break
                case "date":
                    this.state.value = this.props.field.options.defaultValue
                    break
            }
        }
    }
    state = {
        value: ""
    }

    randomId = uid(16)

    handleChange = (key) => {
        return (e) => {
            let value;
            if (e.target.type === "checkbox") {
                value = e.target.checked
            } else {
                value = e.target.value
            }
            this.setState({ [key]: value });
            console.log(e)
        }
    }

    render() {
        switch (this.props.field.type) {
            case "text": return this.renderText()
            case "dropdown": return this.renderDropdown()
            case "checkbox": return this.renderCheckbox()
            case "date": return this.renderDate()
        }
    }

    renderText = () => {
        return (
            <TextField onChange={this.handleChange('value')}
                label={this.props.field.label}
                value={this.state.value}
                placeholder={this.props.field.options.placeholder}
                multiline={this.props.field.options.multiline}
            />
        )
    }
    renderDropdown = () => {
        console.log(this.props.field.options.menuItems)
        const label = this.props.field.label
        return (
            <FormControl>
                <InputLabel id={`field-label-${this.randomId}`}>{this.props.field.label}</InputLabel>
                <Select
                    autoWidth
                    style={{ width: 100 }}
                    labelId={`field-label-${this.randomId}`}
                    id={`field-${this.randomId}`}
                    onChange={this.handleChange('value')}
                    value={this.state.value}
                >
                    <MenuItem key={0} value=""><em>&nbsp;</em></MenuItem>
                    {this.props.field.options.menuItems.map((menuItem) => {
                        return <MenuItem key={menuItem.id} value={menuItem.id}>{menuItem.label}</MenuItem>
                    })}
                </Select>
            </FormControl>
        )
    }
    renderCheckbox = () => {
        return (
            <FormControlLabel
                label={this.props.field.label}
                control={<Checkbox checked={this.state.value} onChange={this.handleChange('value')} />}
            />
        )
    }
    renderDate = () => {
        return (
            <TextField
                type="date"
                label={this.props.field.label}
                InputLabelProps={{
                    shrink: true,
                }}
                value={this.state.value}
                onChange={this.handleChange('value')}
            />
        )
    }
}