import React from 'react'

export default class AccountStats extends React.Component {
    state = {
        maps: []
    }
    componentDidMount() {
        fetch('/api/map', { credentials: "include" })
            .then(response => response.json())
            .then(data => {
                if (data.worked) {
                    this.setState({ maps: data.maps })
                }
            })
    }
    render() {
        return (
            <div>
                <p>Maps created: {this.state.maps.length}</p>
                <p>Locations created: {this.state.maps.reduce((previousValue, map) => {
                    return previousValue + map.details.locations.length
                },0)}</p>
                <p>Events created: {this.state.maps.reduce((previousValue, map) => {
                    return previousValue + map.details.events.length
                },0)}</p>
            </div>
        )
    }
}