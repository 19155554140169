import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { AuthContext } from '../../AuthContext';


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="#">
                Geologger
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    zIndexBost: {
        zIndex: 1,
    },
}));

export default function SignInCore(props) {
    const authContext = useContext(AuthContext)
    const classes = useStyles();
    const [state, setState] = React.useState({
        // emailError: false,
        // passwordError: false,
        submitDisabled: false
    });
    const [errors, setErrors] = React.useState({})

    function handleSubmit(e) {
        e.preventDefault();
        if (validate()) {
            setState({ ...state, submitDisabled: true }) //disable submit button after it is pressed.

            authContext.login({ ...state })
                .then(data => {
                    console.log("data", data)
                    if (data.worked === true) {
                        // props.history.push("/") //Happens automatically when we want it. Messes us up when we want to stay on page.
                    } else {
                        setErrors({ responseError: data.message })
                    }
                })
                .catch(error => console.error(error))
                .finally(() => setState({ ...state, submitDisabled: false })) //re-enable submit button when everything is done.
        }
    }

    function validate() {
        const errs = {}
        if (!state.email || !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i.test(state.email)) {
            //email must exist and be an email
            errs["email"] = "Valid email is required"
        }
        if (!state.password) {
            errs["password"] = "Password is required"
        }
        console.log(errs)
        setErrors(errs)
        return Object.values(errs).length === 0
    }

    function handleChange(key) {
        return (event) => {
            const value = event.target.type == "checkbox" ? event.target.checked : event.target.value
            setState({
                ...state,
                [key]: value
            });
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography className={classes.zIndexBost} component="h1" variant="h5">
                    Log in
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        onChange={handleChange("email")}
                        variant="outlined"
                        margin="normal"
                        // error={state.emailError}
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        error={!!errors["email"]}
                    />
                    <TextField
                        onChange={handleChange("password")}
                        variant="outlined"
                        margin="normal"
                        // error={state.passwordError}
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        error={!!errors["password"]}
                    />
                    {Object.values(errors).map((error) => <p style={{ position: "relative" }}>{error}</p>)}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={state.submitDisabled}
                    >
                        Sign In
                    </Button>
                </form>
            </div>
        </Container>
    );
}
