import { Button, Container, Paper, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { AuthContext } from '../../AuthContext';

export default class ContactForm extends React.Component {
    static contextType = AuthContext;
    handleSubmit = (e) => {
        e.preventDefault()

        //validate
        if (!this.state.email) {
            this.setState({ statusMessage: "Email is required" })
            return
        }
        if (!this.state.subject) {
            this.setState({ statusMessage: "Subject is required" })
            return
        }
        if (!this.state.comment) {
            this.setState({ statusMessage: "Comment is required" })
            return
        }
        const timeoutHandle = setTimeout(()=> {
            this.setState({ statusMessage: "Something went wrong. Please try again later.", submitDisabled: false })
        }, 10000)
        //send
        this.setState({ submitDisabled: true })
        this.context.api.post('/api/contact', {
            email: this.state.email,
            subject: this.state.subject,
            comment: this.state.comment,
        }).then((data) => {
            if (data.worked) {
                clearTimeout(timeoutHandle)
                this.setState({ statusMessage: "Comment submitted successfully" })
            }
        })
    }

    state = {
        email: "",
        subject: "",
        comment: "",


        statusMessage: "",
        submitDisabled: false,
    }

    handleChange(key) {
        return (event) => { this.setState({ [key]: event.target.value }); }
    }

    render() {
        return (
            <Container>
                <Paper style={{ padding: 20 }}>
                    <Typography variant="h3" gutterBottom>Contact Us</Typography>
                    <Typography variant="body1" paragraph>Please contact us with any comments, concerns, or feature requests.</Typography>
                    <form onSubmit={this.handleSubmit}>
                        <div>
                            <TextField value={this.state.email} onChange={this.handleChange("email")} type="email" label="Email" name="email" />
                        </div>
                        <div>
                            <TextField value={this.state.subject} onChange={this.handleChange("subject")} label="Subject" name="subject" />
                        </div>
                        <div>
                            <TextField value={this.state.comment} onChange={this.handleChange("comment")} label="Comment" name="comment" multiline fullWidth style={{ height: 100 }} />
                        </div>
                        {this.state.statusMessage &&
                            <Typography variant="body1">{this.state.statusMessage}</Typography>
                        }
                        <Button disabled={this.state.submitDisabled} type="submit" color="primary" variant="contained">Send</Button>
                    </form>
                </Paper>
            </Container>
        )
    }
}