import { Button, Container, Paper, TextField, Typography } from '@material-ui/core'
import React from 'react'

export default class CustomEventForm extends React.Component {
    state = {
        customFields: []
    }
    render() {
        return (
            <Paper>
                <Container>
                    <h2>Create Custom Event</h2>
                    <form>
                        <div>
                            <TextField id="eventName" label="Event Name" />
                        </div>
                        <p>Fields</p>
                        {this.state.customFields.map((field) => {

                        })}
                        <div>
                            <Button color="primary">+ Add Field</Button>
                        </div>
                        <div>
                            <Button color="primary">Save</Button>
                            <Button color="secondary">Cancel</Button>
                        </div>
                    </form>
                </Container>
            </Paper>
        )
    }
}