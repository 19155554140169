import { Backdrop, Link, Modal, Paper } from '@material-ui/core';
import React from 'react'
import { AuthProvider } from '../AuthContext';
import SignInCore from './SignIn/SignInCore';

export default class Auth extends React.Component {
    state = {
        authenticated: false,
        user: {},
        loginPopup: false,
    }

    login = ({ email, password }) => {
        return new Promise((resolve, reject) => {
            fetch('/api/login', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: email, password: password })
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    if (data.worked) {
                        this.setState({
                            authenticated: true,
                            user: data.user,
                            loginPopup: false,
                        })
                        resolve({ worked: true })
                    } else {
                        if (data.info && (data.info.name === "IncorrectUsernameError" || data.info.name === "IncorrectPasswordError")) {
                            resolve({ worked: false, message: "Incorrect email or password" })
                        }
                        resolve({ worked: false, message: "Something went wrong" })
                    }
                })
                .catch(error => {
                    console.error("Error:", error)
                    resolve({ worked: false, message: "Something went wrong" })
                    // reject(error)
                })
        })
    }

    logout = () => {
        fetch('/api/logout', { credentials: 'include' })
            .then(response => response.json())
            .then(data => {
                console.log('logout response:', data);
                if (data.worked) {
                    this.setState({
                        user: {},
                        authenticated: false,
                    })
                    console.log('logged out.')
                }
            })
    }

    //check if we are actually logged in, and update accordingly
    loginCheck = () => {
        fetch('/api/user')
            .then(response => response.json())
            .then(data => {
                if (data.authenticated) {
                    this.setState({
                        authenticated: true,
                        user: data.user,
                    })
                } else if (data.authenticated === false) {
                    this.setState({
                        authenticated: false,
                        user: null,
                    })
                }
            })
    }

    api = {
        get: (url, authCheck) => this.apiFetch('GET', url, null, authCheck),
        post: (url, body, authCheck) => this.apiFetch('POST', url, body, authCheck),
        delete: (url, body, authCheck) => this.apiFetch('DELETE', url, body, authCheck),
        put: (url, body, authCheck) => this.apiFetch('PUT', url, body, authCheck),
        fetch: (method, url, body, authCheck) => this.apiFetch(method, url, body, authCheck),
    }

    apiFetch(method, url, body, authCheck) {
        console.log('fetching...')
        console.log("method", method)
        console.log("url", url)
        console.log("body", body)
        const requestInit = {
            method: method,
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify(body),
        }
        if (body) {
            requestInit.body = JSON.stringify(body)
        }
        return new Promise((resolve) => {
            fetch(url, requestInit)
                .then(response => {
                    if (response.status === 401) {
                        if (authCheck) {
                            this.setState({ loginPopup: true })
                        } else {
                            this.loginCheck()
                        }
                    }
                    return response.json()
                })
                .then(data => resolve(data))
        })
    }

    test = () => {
        return "Hi we made it."
    }

    //only use for testing
    isAuthenticated = () => {
        return this.state.authenticated
    }

    componentDidMount() {
        //on page refresh we lose everything,
        //so we need to check with the server to see
        //if we are still logged in.
        this.loginCheck()
    }

    render() {
        const AuthProviderValue = {
            ...this.state,
            login: this.login,
            logout: this.logout,
            test: this.test,
            isAuthenticated: this.isAuthenticated,
            loginCheck: this.loginCheck,
            api: this.api
        }
        return (
            <AuthProvider value={AuthProviderValue}>
                {this.state.loginPopup &&
                    <div style={{ position: 'absolute'}}>
                        <Modal open>
                            <Backdrop open>
                                <Paper style={{ padding: 20, maxWidth: 500 }}>
                                    <SignInCore />
                                    <Link href="/">Go to homepage</Link>
                                </Paper>
                            </Backdrop>
                        </Modal>
                    </div>
                }
                {this.props.children}
            </AuthProvider>
        )
    }
}