import { Button, Container as div, TextField } from '@material-ui/core'
import React from 'react'

export default class ChangePasswordForm extends React.Component {

    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.handleChangePassword = this.handleChangePassword.bind(this)
    }
    state = {
        statusMessage: "",
        oldPassword: "",
        newPassword: "",
        newRetypedPassword: "",
        retypePasswordError: false,
        submitDisabled: false,
    }

    handleChangePassword() {
        if (this.state.newPassword !== this.state.newRetypedPassword) {
            this.setState({ retypePasswordError: true })
            return
        }
        this.setState({ retypePasswordError: false, submitDisabled: true })
        fetch('/api/changepassword', {
            method: 'post',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                oldPassword: this.state.oldPassword,
                newPassword: this.state.newPassword,
            })
        }).then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                if (data.worked) {
                    this.setState({
                        statusMessage: "Password changed!",
                        oldPassword: "",
                        newPassword: "",
                        newRetypedPassword: "",
                    })
                } else {
                    if (data.error === "IncorrectPasswordError") {
                        this.setState({
                            statusMessage: "Incorrect password.",
                            oldPassword: "",
                            newPassword: "",
                            newRetypedPassword: "",
                            submitDisabled: false,
                        })
                    }
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({
                    statusMessage: "Something went wrong.",
                    oldPassword: "",
                    newPassword: "",
                    newRetypedPassword: "",
                })
            });

    }
    handleChange(key) {
        return (event) => { this.setState({ [key]: event.target.value }); }
    }

    render() {
        return (
            <div>
                <h2>Change Password</h2>
                <div>
                    <TextField
                        label="Old Password"
                        type="password"
                        autocomplete="off"
                        onChange={this.handleChange("oldPassword")}
                        value={this.state.oldPassword}
                    />
                </div>
                <div>
                    <TextField
                        label="New Password"
                        type="password"
                        autocomplete="off"
                        onChange={this.handleChange("newPassword")}
                        value={this.state.newPassword}
                        error={this.state.retypePasswordError}
                    />
                </div>
                <div>
                    <TextField
                        label="Retype New Password"
                        type="password"
                        autocomplete="off"
                        onChange={this.handleChange("newRetypedPassword")}
                        value={this.state.newRetypedPassword}
                        error={this.state.retypePasswordError}
                    />
                </div>
                <div>
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginTop: 10 }}
                        onClick={this.handleChangePassword}
                        disabled={this.state.submitDisabled}
                    >
                        Change Password
                    </Button>
                </div>
                <div>
                    <p>{this.state.statusMessage}</p>
                </div>

            </div>
        )
    }
}