import './App.css';
import Map from './components/Map/Map.js';
import CreateMapForm from './components/CreateMapForm/CreateMapForm';
import TopBar from './components/TopBar/TopBar.js'
import { Route, BrowserRouter } from 'react-router-dom';
import SignIn from './components/SignIn/SignIn';
import SignUp from './components/SignUp/SignUp';
import Auth from './components/Auth';
import LandingPage from './components/LandingPage/LandingPage';
import EventView from './components/EventView/EventView';
import Analytics from './Analytics'
import AccountSettings from './components/AccountSettings/AccountSettings'
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import ContactForm from './components/ContactForm/ContactForm';
import CustomEventForm from './components/CustomEventForm/CustomEventForm';
import CustomFieldForm from './components/CustomEventForm/CustomFieldForm/CustomFieldForm';
// import { SnackbarProvider } from 'notistack'

function App() {
  return (
    <div className="App">
      <Auth>
        <BrowserRouter>
          <Analytics>
            <TopBar />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Route exact path="/contact" component={ContactForm} />
            <Route exact path="/sign-in" component={SignIn} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/">
              <LandingPage />
            </Route>
            <Route exact path="/account-settings">
              <AccountSettings />
            </Route>
            <Route exact path="/map/:mapId" render={(props) => <Map mapId={props.match.params.mapId} />} />
            <Route exact path="/events/:mapId" render={(props) => <EventView mapId={props.match.params.mapId} />} />
            <Route exact path="/map-options/:mapId" render={(props) => <CreateMapForm mapId={props.match.params.mapId} />} />
            <Route exact path="/create-map" component={CreateMapForm} />
            <Route exact path="/custom-event" component={CustomEventForm} />
            <Route exact path="/map/:mapId/custom-field" component={CustomFieldForm} />
          </Analytics>
        </BrowserRouter>
      </Auth>
    </div>
  );
}

export default App;
