import React from 'react'
import { AuthContext } from '../../AuthContext';
import LandingPageAuthenticated from './LandingPageAuthenticated';
import LandingPageUnauthenticated from './LandingPageUnauthenticated';

export default class LandingPage extends React.Component {
    static contextType = AuthContext;

    state = {
        userMaps: []
    }

    componentDidMount() {
        console.log('Landing page mounted.')
        if (this.context.authenticated) {
            console.log('Getting maps for user.')
            fetch('/api/map', { credentials: "include" })
                .then(response => response.json())
                .then(data => {
                    console.log('Data returned', data)
                    if (data.worked) {
                        this.setState({ userMaps: data.maps })
                    }
                })
        }
    }

    render() {
        return (
            <div style={{display: "flex", flexGrow: 1}}>
                {this.context.authenticated &&
                    <LandingPageAuthenticated />
                }
                {!this.context.authenticated &&
                    <LandingPageUnauthenticated />
                }
            </div>
        )
    }
}