import React from 'react'
import { useLocation } from 'react-router-dom'
import { AuthContext } from './AuthContext'

function usePageViews() {
    const auth = React.useContext(AuthContext)
    const user = auth.user;
    let location = useLocation();
    if (auth.isAuthenticated()) {
        if (document.location.hostname !== "localhost") {
            window.gtag('set', {
                userId: user._id
            })
        }
    }
    React.useEffect(() => {
        if (document.location.hostname !== "localhost") {
            window.gtag('send', 'page_view', {
                page_location: window.location.href,
                page_path: window.location.pathname
            })
        }
    }, [location])
    React.useEffect(() => {
        if (auth.isAuthenticated()) {
            if (document.location.hostname !== "localhost") {
                window.gtag('set', {
                    userId: user._id
                })
            }
        }
    }, [user])
}

export default function Analytics(props) {

    usePageViews()
    return props.children
}