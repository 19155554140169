import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box';
import React from 'react'
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import LoginSignUp from './LoginSignUp';

class LandingPageUnauthenticated extends React.Component {
    componentDidMount() {

    }

    render() {
        return (
            <div style={{
                flexGrow: 1,
                backgroundImage: 'url(/public/sunset-compressed.jpeg)',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
            }}>
                {/* Fix this lol */}
                <p><br></br></p>
                <LoginSignUp />
                <Box mt={5} style={{position: "fixed", bottom: "10px ", left: "50%", transform:"translate(-50%,0)"}}>
                    <Typography variant="body2" color="textSecondary" align="center">
                        Geologger
                        {' © '}
                        {new Date().getFullYear()} | <Link target="_blank" to="/privacy">Privacy Policy</Link> | <Link target="_blank" to="/contact">Contact Us</Link>
                    </Typography>
                </Box>
            </div>
        )
    }
}

export default withRouter(LandingPageUnauthenticated)