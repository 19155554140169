import { Tab, Tabs } from '@material-ui/core'
import { TabPanel } from '@material-ui/lab'
import React from 'react'
import { TabContext } from '@material-ui/lab'
import SignUpCore from '../SignUp/SignUpCore'
import SignInCore from '../SignIn/SignInCore'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'

const styles = (theme) => ({
    box: {
        background: "inherit",
        position: "absolute",
        overflow: "hidden",
        left: "50%",
        maxWidth: "470px",
        marginLeft: "-235px",
        '&::before': {
            content: '""',
            background: 'inherit',
            position: 'absolute',
            left: -25,
            right: 0,
            top: -25,
            bottom: 0,
            boxShadow: 'inset 0 0 0 3000px rgba(255,255,255,0.6)',
            filter: 'blur(20px)',
        }
    }
});

class LoginSignUp extends React.Component {
    state = {
        value: "0",
    }
    handleChange = (event, newValue) => {
        this.setState({ value: newValue })
    }
    render() {
        return (
            <div className={this.props.classes.box}>
                {/* <Paper> */}
                <div>
                    <TabContext value={this.state.value}>
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Log In" value="0" />
                            <Tab label="Sign Up" value="1" />
                        </Tabs>
                        <TabPanel value="0">
                            <SignInCore />
                        </TabPanel>
                        <TabPanel value="1">
                            <SignUpCore />
                        </TabPanel>
                    </TabContext>
                </div>
                {/* </Paper> */}
            </div>
        )
    }
}


LoginSignUp.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginSignUp);