import { Button, Card, CardActions, CardContent, Link, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../AuthContext'


const useStyles = makeStyles({
    root: {
        minWidth: 275
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    button: {
        marginLeft: 5,
        marginRight: 5,
    }
});


export default function MapCard(props) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Card variant="outlined" className={classes.root}>
            <CardContent>
                {/* <Link to={`/map/${props.map._id}`}> */}
                <Typography variant="h6" component="h2">
                    {props.map.name}
                </Typography>
                {/* </Link> */}
                <Typography variant="body2" component="p">
                    {props.map.description}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    className={classes.button}
                    size="small"
                    color="primary"
                    onClick={() => history.push(`/map/${props.map._id}`)}>
                    Map View
                </Button>|
                <Button
                    className={classes.button}
                    size="small"
                    color="primary"
                    onClick={() => history.push(`/events/${props.map._id}`)}>
                    Event View
                </Button>|
                <Button
                    className={classes.button}
                    component={Link}
                    // href={`/api/map/${props.map._id}/export`}
                    href={`/api/map/${props.map._id}/export`}
                    target="_blank"
                    download
                    rel="noopener noreferrer"
                    size="small"
                    color="primary"
                >
                    Download Excel
                </Button>|
                <Button
                    className={classes.button}
                    size="small"
                    color="primary"
                    onClick={() => history.push(`/map-options/${props.map._id}`)}>
                    Options
                </Button>
            </CardActions>
        </Card>
    )
}