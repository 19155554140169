import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import EventGrid from './EventGrid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Modal } from '@material-ui/core';
import CreateEventForm from '../CreateEventForm/CreateEventForm2';

const styles = (theme) => ({
    root: {
        margin: 10
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
});

/**
 * A view in addition to the map view.
 * Displays events as cards.
 * requires props.mapId
 */
class EventView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            events: [],
            locations: [],
            tags: [],
            modalOpen: false,
            modalContents: <div></div>,
        }


        this.updateMapData = this.updateMapData.bind(this)
        this.handleEventSave = this.handleEventSave.bind(this)
        this.handleEventDelete = this.handleEventDelete.bind(this)
    }

    componentDidMount() {
        this.updateMapData();
    }

    updateMapData(mapUpdateData) {
        console.log("mapUpdateData", mapUpdateData)
        let update = function (mapData) {
            const updatedEvents = mapData.details.events.map(event => {
                event.date = new Date(event.date)
                return event;
            });

            //get all the tags before updating state.
            const nonUniqueTags = mapData.details.events.map(event => event.tags).reduce((a, x) => [...a, ...x], [])

            //update events and locations then filter them.
            this.setState({
                events: updatedEvents.map((event) => { return { ...event, key: event._id } }),
                locations: mapData.details.locations.map((location) => { return { ...location, key: location._id } }),
                tags: [...new Set(nonUniqueTags)].sort()
            }, this.filterData)

            console.log(mapData);
            console.log('Updated Map Data')
        }
        update = update.bind(this);

        if (mapUpdateData) {
            update(mapUpdateData)
        } else {
            fetch(`/api/map/${this.props.mapId}`)
                .then(response => response.json())
                .then(data => {
                    console.log("DATA", data)
                    if (data.worked) {
                        update(data.data)
                    }
                })
        }
    }

    handleEventSave(data) {
        this.updateMapData(data.updatedMap)
        this.setState({ modalOpen: false })
    }

    handleEventDelete(data) {
        this.updateMapData(data.updatedMap)
        this.setState({ modalOpen: false })
    }

    filterData() {
        console.log('pseudo filtering data')
    }

    closeModal = () => {
        this.setState({ modalOpen: false })
    }
    openCreateEventModal = () => {
        this.setState({
            modalContents: (
                <div className={this.props.classes.paper}>
                    <CreateEventForm
                        mapId={this.props.mapId}
                        locations={this.state.locations}
                        availableTags={this.state.tags}
                        options={{}}
                        onSave={this.handleEventSave}
                        onDelete={this.handleEventDelete}
                        onCancel={this.closeModal}
                    />
                </div>
            ),
            modalOpen: true,
        })
    }

    openEditEventModal = (event) => {
        this.setState({
            modalContents: (
                <div className={this.props.classes.paper}>
                    <CreateEventForm
                        mapId={this.props.mapId}
                        locations={this.state.locations}
                        availableTags={this.state.tags}
                        event={event}
                        options={{}}
                        onSave={this.handleEventSave}
                        onDelete={this.handleEventDelete}
                        onCancel={this.closeModal}
                    />
                </div>
            ),
            modalOpen: true,
        })
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.closeModal}
                >
                    {this.state.modalContents}
                </Modal>
                <h2>Events</h2>
                <EventGrid events={this.state.events} locations={this.state.locations} onEventClick={this.openEditEventModal} />
                <Fab onClick={this.openCreateEventModal} color="primary" variant="extended" aria-label="add" className={this.props.classes.fab}>
                    <AddIcon />
                    Add Event
                </Fab>
            </div>
        )
    }
}

EventView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventView);