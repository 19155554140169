import { AppBar, Toolbar, Button, Typography, IconButton, Menu, MenuItem } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import React from 'react';
import { AuthContext } from '../../AuthContext';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { withRouter } from "react-router";

const styles = (theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        // flexGrow: 1,
        cursor: 'pointer',
        userSelect: 'none',
    },
    middleGap: {
        flexGrow: 1,
    }
});

class TopBar extends React.Component {
    static contextType = AuthContext;

    state = {
        anchorEl: null
    }

    componentDidMount() {

    }

    handleMenu = (e) => {
        this.setState({ anchorEl: e.currentTarget })
    }

    handleMenuClose = () => {
        this.setState({ anchorEl: null })
    }

    handleLogout = () => {
        this.handleMenuClose()
        this.context.logout()
        this.props.history.push('/')
    }

    handleLink = (link) => {
        return () => {
            this.handleMenuClose()
            this.props.history.push(link)
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div id="topbar">
                <AppBar>
                    <Toolbar variant="dense">
                        {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton> */}
                        <Typography variant="h6" className={classes.title} onClick={this.handleLink("/")}>Geologger</Typography>
                        <div className={classes.middleGap} />
                        {/* <Typography style={{cursor: 'pointer'}} variant="h6" className={classes.title} onClick={this.handleLink("/")}>Geologger</Typography> */}
                        {!this.context.authenticated &&
                            <div>
                                {/* <Button color="inherit" onClick={this.handleLink("/")} >Home</Button> */}
                                {/* <Button color="inherit" onClick={this.handleLink("/sign-in")} >SignIn</Button>
                                <Button color="inherit" onClick={this.handleLink("/sign-up")} >SignUp</Button> */}
                            </div>
                        }
                        {this.context.authenticated &&
                            <div>
                                <Button color="inherit" onClick={this.handleLink("/")} >Maps</Button>
                                <Button color="inherit" onClick={this.handleLink("/create-map")} >Create Map</Button>
                            </div>
                        }
                        {/* <Link hidden={this.context.authenticated} to="/sign-in"><Button color="inherit">SignIn</Button></Link>
                        <Link hidden={this.context.authenticated} to="/sign-up"><Button color="inherit">SignUp</Button></Link>
                        <Link hidden={!this.context.authenticated} to="/"><Button color="inherit">Maps</Button></Link>
                        <Link hidden={!this.context.authenticated} to="/create-map"><Button color="inherit">Create Map</Button></Link> */}
                        {this.context.authenticated && <div>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={this.handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={this.state.anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                open={!!this.state.anchorEl}
                                onClose={this.handleMenuClose}
                            >
                                <MenuItem onClick={this.handleLink("/account-settings")}>Account Settings</MenuItem>
                                <MenuItem onClick={this.handleLogout}>Log Out</MenuItem>
                            </Menu>
                        </div>}
                    </Toolbar>
                </AppBar>
                <Toolbar variant="dense" />
            </div >
        )
    }
}

TopBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(TopBar));